@font-face {
  font-family: "Bobby Jones Soft W05 Condensed";
  src:url("/fonts/Bobby_Jones_Soft_W05_Condensed.woff2") format("woff2"),url("/fonts/Bobby_Jones_Soft_W05_Condensed.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Bobby Jones Soft W05 Regular";
  src:url("/fonts/Bobby_Jones_Soft_W05_Regular.woff2") format("woff2"),url("/fonts/Bobby_Jones_Soft_W05_Regular.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Dazzed-Bold";
  src:url("/fonts/Dazzed-Bold.woff2") format("woff2"),url("/fonts/Dazzed-Bold.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Dazzed-Medium";
  src:url("/fonts/Dazzed-Medium.woff2") format("woff2"),url("/fonts/Dazzed-Medium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Dazzed-Medium-Italic";
  src:url("/fonts/Dazzed-MediumItalic.woff2") format("woff2"),url("/fonts/Dazzed-MediumItalic.woff") format("woff");
  font-display: swap;
}

html {
    position: relative;
    min-height: 100vh;
}
  
body {
        margin: 0;
        padding: 0;
        font-family: 'Dazzed-Medium' !important;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        background-color:#fff !important;
        min-height: 100vh;
        color: #004839 !important;
        /*min-width:380px;*/
}

a:hover {
  text-decoration: none;
}

mark {
  padding-left: 0.05rem !important;
  padding-right: 0.05rem !important;
  background-color: #ffea7e !important;  /*#fcf8e3;*/
}

.logo {
  text-decoration: none;
}

.logo:hover {
  text-decoration: none;
}

.submenuLink {
  color: #004839 !important;
  text-decoration:underline;
}

.submenuBoldLink {
  color: #212529 !important;
  text-decoration:underline;
  font-weight:bold;
}

.borderLeft {
  border-left: 1px solid #004839 !important;
}

.externalLinkImage {
    height:10px;
    vertical-align: top;
    padding-left:3px;
}
  
.opacity50 {
    opacity: 0.5;
}

.opacity30 {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /*
  -webkit-animation-delay: .0.5s;
  animation-delay: .0.5s;
  */
  -webkit-animation-name: blendOut;
  animation-name: blendOut;
}

.color006384 {
  color: #004839 !important;
}

.link {
  color:#004839 !important;
  text-decoration:underline !important;
}

.pictureLink {
  color: #000 !important;
  text-decoration: none;
}

.pictureLink:hover {
  text-decoration: none;
}

h1,h2,h3,h4 {
  color: #004839 !important;
}

body > .container {
    padding: 150px 15px 0;
}

.zIndex101 {
  z-index:101 !important;
  position: relative !important;
}

.upper {
  z-index: 1015 !important;
  position: relative !important;
}

.lower {
  z-index: 1 !important;
  position: relative !important;
}

.show {
  z-index:1001 !important;
  position:relative;
}

.selectpicker::after{
  z-index: 1002 !important;
  position:absolute !important;
}

.flag {
  display: block;
  max-width: 100%;
  width:75px !important;
  height: 50px;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  border:1px solid #eee;
  background-position: center;
}

.flagSelect {
  display:  inline-block;
  max-width: 100%;
  width:30px !important;
  height: 20px;
  border-radius: 5px;
  background-size: cover !important;
  background-repeat: no-repeat;
  border:1px solid #eee;
  background-position: center;
}

.fi-xk {
  background-image: url("/images/kosovo.png");
}

.fi-group {
  background-image: url("/images/GrolmanLogo_Root_100.png");
  background-size: contain !important;
  background-color:#fff;
}

.underline {
  text-decoration: underline;
}

.marginLeft10px {
  margin-left:10px;
}

.marginLeft15px {
  margin-left:15px;
}

.margin50px {
  margin:50px;
}

.marginTop25px {
  margin-top:25px;
}

.marginTop50px {
  margin-top:50px;
}

.marginBottom50px {
  margin-bottom:50px;
}

.padding2px {
  padding:2px;
}

.padding5px {
  padding:5px;
}

.padding10px {
  padding:10px;
}

.padding50px {
  padding:50px;
}

.fontSizeXSmall {
  font-size:x-small;
}

.displayInlineBlock {
  display: inline-block !important;
}

.displayNone {
  display:none;
}

.displayBlock {
  display:block !important;
}

.overflowYScrollHeight400px {
  overflow-y: scroll;
  height: 400px;
}

.imageLink:hover {
  border-radius:5px;
  color:#fff !important;
  -webkit-animation-name: backgroundIn;
  animation-name: backgroundIn;
}

.productLink:hover {
  border-radius:5px;
  color:#fff !important;
  -webkit-animation-name: backgroundIn;
  animation-name: backgroundIn;
}

.button {
  background-color: #004839;
  border-radius:5px;
  color:#fff !important;
  padding:10px;
}

.color000 {
  color:#000;
}

.color18657a {
  color: #004839 !important;
}

.carouselText {
  background-color: #fff;
  opacity: 0.6;
  color: #004839;
  display:block;
  padding:5px;
  height: 40px;
}

.carouselFacebookLinkText {
  color: #FD9102;
  font-weight:bold;
}

.carousel-indicators {
  position:absolute;
  bottom: 110px !important;
}

.carousel-indicators .active {
  background-color: #004839 !important;
}

.carousel-caption {
  position: relative !important;
  right:auto !important;
  left:auto !important;
  bottom: 55px !important;
}

.backgroundColorAliceBlue {
  background-color:aliceblue;
}

.backgroundColorWhite {
  background-color:#fff;
}

.sticky-inner-wrapper {
  background-color:#fff;
  height:100px;
}

.borderRadius10px {
  border-radius:10px;
}

.border1pxSolide0e0e0 {
  border: 1px solid #e0e0e0;
}

.border1pxSolid004839 {
  border: 1px solid #004839;
}

.listStyleNone {
  list-style: none;
}

.floatLeft
{
  float:left;
}

#wrap {
  height: calc(100vh - 150px);
}

#main {
  min-height:100%;
}

#content {
  height:100%;
}


#root {
    background-color:#fff;
  min-height:100vh;
}

.spacing:before {
  height: 130px !important;
  content: "";
  display:block;
}

.container {
  background-color:#fff;
  border-radius:5px;
}

.container-fluid {
  background-color:#fff;
  border-radius:5px;
}

.cursorPointer {
  cursor:pointer;
}

.languageSelection {
  width:30px;
}

.mainProductGroupDiv {
  border-left:0px !important;
  border-right:0px !important;
  background-color: #BACCFF !important;
  color: #004839 !important; 
}

.mainProductGroupLink:hover {
  /*
  -webkit-animation-name: backgroundIn;
  animation-name: backgroundIn;
  */
}

.mainProductGroupLink:hover > a {
  cursor:pointer;
  text-decoration: none;
  color:#fff !important;
}

.mainProductGroupLink > a {
  cursor:pointer;
  text-decoration: none;
  color:#004839 !important;
}

.mainProductGroupLink > a:hover {
  cursor:pointer;
  text-decoration: none;
  color:#fff !important;
}

#background {
  position:fixed;
  left:0px;
  top:0px;
  width:100%;
  height:100%;
  /*background-image: linear-gradient(#1391b5 65%, rgba(156,175,201,0) 100%);*/
  background: radial-gradient(50%, rgba(156,175,201,0) 0%,#004839 80%);
  background: -webkit-radial-gradient(50%, rgba(156,175,201,0) 0%,#004839 80%);
  background: -moz-radial-gradient(50%, rgba(156,175,201,0) 0%,#004839 80%);
  background: -o-radial-gradient(50%, rgba(156,175,201,0) 0%,#004839 80%);
  background: -ms-radial-gradient(50%, rgba(156,175,201,0) 0%,#004839 80%);
  height: 100% !important;
}

.floating {
  float:left;
  padding:10px;
}

.navigation {
  margin-top:140px;
}

a.anchor {
  display: block;
  position: relative;
  top: -140px;
  visibility: hidden;
}

.nav-link {
  font-size:0.91rem !important;
}

.newNavLink {
  color: #004839;
}

.borderBottom1pxSolid888  {
  border-bottom:1px solid rgba(0, 0, 0, 0.1)
}

.phone {
  width:20px;
  height:20px;
}

.phoneMain {
  width:25px;
  height:25px;
  color:#000 !important;
}

.pdfImage {
  height: 50px;
}

.emailMain {
  width:35px;
  height:35px;
  color:#000 !important;
}

.whiteSpaceNoWrap {
  white-space:nowrap;
}

.white {
  color:#fff;
}

.width100Percent {
  width:100%;
}


.animDelay2  {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
}

@keyframes blendIn{
  0% {
    opacity: 0.3;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes blendOut{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@keyframes fadeInDown{
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp{
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes backgroundIn{
  0% {
    background-color: #fff;
    color:#000;
  }
  100% {
    background-color: #004839;
    color:#fff;
  }
}

@keyframes colorIn {
  0% {

    color:#004839;
  }
  100% {
    color:#fff;
  }
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2318657a' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2318657a' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.width75px {
  width:75px;
}

#pumpkinImage {
  position:relative;
  z-index:-1;
  top:-280px;
}

.padding0px {
  padding:inherit;
}

.padding5px {
  padding:inherit;
}


@media (max-width: 576px) {
  
  .padding0px {
    padding-left:0px !important;
    padding-right:0px !important;
    padding-top:10px !important;
    padding-bottom:10px !important;
  }

  .padding5px {
    padding-left:5px !important;
    padding-right:5px !important;
  }
  
}

.overflowHidden {
  overflow:hidden;
}

.backgroundTransparent {
  background-color:transparent !important;
}

.colorText18657aBold {
  color: #004839;
  font-weight: bold;
}

.svgColor {
  fill: currentColor;
}

.height50px {
  height: 50px !important;
}

.minWidthFitContent {
  min-width: fit-content;
}

.optOutPrivacyLink {
  color: #004839;
  text-decoration: underline;
}

.optOutPrivacyLink:hover {
  color:#004839;
  text-decoration: underline;
}

.internalLink {
  color: #004839 !important;
  text-decoration: underline;
}

.internalLink:hover {
  color: #004839 !important;
  text-decoration: underline;
}

video {
  background-color: transparent !important;
}

.sliderButtons .slick-prev::before, .sliderButtons .slick-next::before {
  color:#004839;
}

.navbar-brand {
  margin-right:auto !important;
}

.resourcesLink {
  color:#000;
  text-decoration:underline;
}

.resourcesLink:hover {
  color:#000;
  text-decoration:underline;
}

.podcastLink {
  font-family: "Bobby Jones Soft W05 Condensed";
  color:#004839;
  font-size: 1.25rem;
}

.podcastLink:visited {
  color:#004839;
}